








import { Component, Vue } from "vue-property-decorator"
import HeroSection from "@/components/home/HeroSection.vue"
import GettingStarted from "@/components/home/GettingStarted.vue"
import SummaryColumn from "@/components/home/SummaryColumn.vue"
import Affiliates from "@/components/home/Affiliates.vue"

@Component({
  components: {
    HeroSection,
    GettingStarted,
    SummaryColumn,
    Affiliates,
  },
  metaInfo: {
    title: "Discord4J - The fastest Discord API wrapper written in Java",
  },
})
export default class Home extends Vue {}
