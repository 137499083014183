


































import { Component, Vue } from "vue-property-decorator"
import Prism from "vue-prism-component"
import axios from "axios"
import gs, { TextLines } from "@/snippets/getting-started"

const VERSIONS_API = "https://api.discord4j.com/versions"

interface VersionResponse {
  prerelease: boolean
  tag: string
  title: string
  url: string
}

@Component({
  components: {
    Prism,
  },
})
export default class GettingStarted extends Vue {
  public indexes: TextLines[] = [gs.gradle, gs.maven, gs.sbt]
  public index: number = 0 // 0, 1, 2 for selecting gradle, maven, or sbt
  private version: string = gs.version

  /**
   * Sets the height of the code snippet box based on the number of lines in the text
   */
  public get heightPx(): string {
    return this.indexes[this.index].lines * 21 + 51 + "px"
  }

  /**
   * Sets the current index for displaying the chosen configuration snippet
   */
  public setIndex(num: number): void {
    this.index = num
  }

  public mounted() {
    axios.get(VERSIONS_API).then(response => {
      const versions: VersionResponse[] = response.data
      gs.version = versions.find(v => !v.prerelease)!.tag
      this.version = gs.version
      this.indexes = [gs.gradle, gs.maven, gs.sbt]
    })
  }
}
