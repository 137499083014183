





import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class AffiliateLink extends Vue {
  @Prop() private src!: string
  @Prop() private img!: string
}
