








import { Component, Vue } from "vue-property-decorator"
import AffiliateLink from "@/components/home/AffiliateLink.vue"

@Component({
  components: {
    AffiliateLink,
  },
})
export default class Affiliates extends Vue {}
