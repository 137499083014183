
















import { Component, Vue } from "vue-property-decorator"
import LogoText from "@/components/logo/LogoText.vue"

@Component({
  components: {
    LogoText,
  },
})
export default class HeroSection extends Vue {}
