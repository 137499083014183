































import { Component, Vue } from "vue-property-decorator"
import ReactorLogo from "@/components/logo/ReactorLogo.vue"

@Component({
  components: {
    ReactorLogo,
  },
})
export default class SummaryColumn extends Vue{}
